import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: {
      lighten2: '#FFFFFF',
      vTabs: '#121212'
    }
  },
})
