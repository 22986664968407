<template>
  <v-app>
    <v-navigation-drawer v-model="sidebar" app v-if="$route.meta.header !== false && this.$vuetify.breakpoint.smAndDown" >
      <v-list>
        <v-list-item-group>
          <template v-for="linkItem in linkItems" v-if="linkItem['loginRequired'] && loggedIn || !linkItem['loginRequired']">
            <v-list-item v-if="((linkItem['loginRequired'] && loggedIn) || !linkItem['loginRequired']) && !(loggedIn && linkItem['loginForbidden'])">
              <v-list-item-content>
                <router-link :to="linkItem['path']">
                  <v-btn text class="mr-2">
                    {{ linkItem['title'] }}
                  </v-btn>
                </router-link>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item>
            <v-list-item-content>
              <v-btn text class="mr-2" v-if="loggedIn" v-on:click="logout()">
                Logout
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

      </v-list>
    </v-navigation-drawer>

    <v-app-bar app v-if="$route.meta.header !== false">
      <span class="hidden-md-and-up">
        <v-app-bar-nav-icon v-on:click="sidebar = !sidebar"></v-app-bar-nav-icon>
      </span>
      <v-toolbar-title>
        <router-link :to="{name: 'Home'}" style="text-decoration: none; color:inherit">
          <div style="display: flex; align-items: center">
            <img style="height: 3rem; width: 3rem; margin: 5px" src="@/assets/TFSK/TFSK_EarthDark.png"/>
            <h1 class="hidden-md-and-down">The Flight Sim Kit</h1>
            <h2 class="hidden-lg-and-up">The Flight Sim Kit</h2>
          </div>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down" style="align-items: center">
        <div class="mr-2" v-if="currentUser">
          <span>{{currentUser['user']}}</span>
        </div>
        <router-link v-for="linkItem in linkItems" :to="linkItem['path']" >
          <v-btn text class="mr-2" v-if="((linkItem['loginRequired'] && loggedIn) || !linkItem['loginRequired']) && !(loggedIn && linkItem['loginForbidden'])">
            {{linkItem['title']}}
          </v-btn>
        </router-link>

        <v-btn text class="mr-2" v-if="loggedIn" v-on:click="logout()">
          Logout
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import {mapGetters} from "vuex";
import styles from './app.css'

export default {
  name: 'App',

  components: {
  },
  created() {
    this.$store.dispatch('loadUser')
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      loggedIn: 'isLoggedIn'
    })
  },
  data: () => ({
    sidebar: false,
    linkItems: [
      {title: 'Home', path: '/', loginRequired: false},
      {title: 'Flights', path: '/flights', loginRequired: true},
      {title: 'Fleet', path: '/fleet', loginRequired: true},
      {title: 'Live Map', path: '/livemap', loginRequired: false},
      {title: 'Account', path: '/account', loginRequired: true},
      {title: 'Social', path: '/social', loginRequired: true},
      {title: 'Help', path: '/help', loginRequired: false},
      {title: 'Login', path: '/login', loginRequired: false, loginForbidden: true},
      {title: 'Register', path: '/register', loginRequired: false, loginForbidden: true}
      ]
  }),
  methods: {
    logout: function (){
      this.$store.dispatch('logoutUser')
      this.$router.push({name: "Home"})
    }
  }
}
</script>

<style scoped>
  a {
    text-decoration: none;
  }

</style>
