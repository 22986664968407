import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { Icon } from 'leaflet';

import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import "@/assets/global.css"
import { GestureHandling } from "leaflet-gesture-handling";
import 'intl'
import 'intl-collator'
import 'intl/locale-data/jsonp/en-US'
import 'intl/locale-data/complete'

Vue.config.productionTip = false

// This is a marker fix for leaflet
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

if (process.env.NODE_ENV === 'production'){
  Vue.prototype.$baseURL = new URL(window.location).origin
}else{
  Vue.prototype.$baseURL = 'http://localhost:8080'
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
