<template>
  <div class="home">
    <v-container>
      <hr>
      <h1 style="text-align: center">Live Flights</h1>
      <hr>
      <div>
        <div v-if="currentData !== null">
          <v-row>
            <v-col md="8" cols="12" style="height:35rem; display: flex; flex-direction: column">
              <l-map :zoom="zoom" :center="mapCenter" style="z-index: 0;" gestureHandling :options="{gestureHandling: true, worldCopyJump: true}">
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <v-rotated-marker v-for="eachUser in currentData" :lat-lng="getLatLng(eachUser['Data']['latitude'], eachUser['Data']['longitude'])" :icon="icon" :rotationAngle="radians_to_degrees(eachUser['Data']['heading'])" >
                  <l-tooltip>
                    {{eachUser['Username']}}
                  </l-tooltip>
                </v-rotated-marker>
              </l-map>
            </v-col>
            <v-col md="4" cols="12">
              <h3>Online Pilots: {{pilotCount}}</h3>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <h1> Waiting for connection... </h1>
        </div>
      </div>

      <div v-if="dataInErrorState">
        Error Oh Noes
      </div>
      <hr>
      <h1 style="text-align: center">Features</h1>
      <hr>
      <div style="width: 80%; margin-left: 10%; margin-right: 10%">
        <v-row>
          <v-col md="6" cols="12">
            <div>
              <img style="width: 100%; margin: 5px" src="@/assets/screenshots/planning_and_current.png"/>
            </div>
          </v-col>
          <v-col md="6" cols="12" class="infoText">
            <div>
            <h3>Flight Planning With SimBrief</h3>
              <p>
                Track all of your flights through The Flight Sim Kit! <br>
                We have tight integration with SimBrief with both the ability to generate a plan directly though
                The Flight Sim Kit using SimBrief and an option to import an existing plan generated on SimBrief. <br>
                We also offer the option to load your flight plan from an MSFS .PLN file. <br>
                Whilst flying a live map is presented of the flight along with the planned route. The live map shows
                current weather and optionally can show ongoing VATSIM ATC and VATSIM flights. We also show the plan
                waypoints and the full SimBrief OFP can be opened.
              </p>
            </div>
          </v-col>
        </v-row>
        <hr>
        <v-row>
          <v-col md="6" cols="12" class="infoText">
            <div>
              <h3>Fine Grained Path Logging</h3>
              <p>
                Track all of your flights through The Flight Sim Kit! <br>
                We support the tracking of both IFR flights and VFR flights. We support the ability to store unlimited
                flights in high detail. We store the aircraft speed, location, altitude etc at two second intervals
                allowing incredibly fine grained logging.
              </p>
            </div>
          </v-col>
          <v-col md="6" cols="12">
            <div>
              <img style="width: 100%; margin: 5px" src="@/assets/screenshots/path_log.png"/>
            </div>
          </v-col>
        </v-row>
        <hr>
        <v-row>
          <v-col md="6" cols="12">
            <div>
              <img style="width: 100%; margin: 5px" src="@/assets/screenshots/landing.png"/>
            </div>
          </v-col>
          <v-col md="6" cols="12" class="infoText">
            <div>
              <h3>Landing Data Logging</h3>
              <p>
                Track and obtain data from all of your landings through The Flight Sim Kit! <br>
                We store detailed touchdown data displaying a wide range of data from wind on approach, vertical speed
                on landing, GForce and many more. <br>
                For additional analysis we also offer a map of landing location vs the landing threshold allowing users
                to see the exact touch down point of their aircraft!
              </p>
            </div>
          </v-col>
        </v-row>
        <hr>
        <v-row>
          <v-col md="6" cols="12" class="infoText">
            <div>
              <h3>Flight Plan Schedules</h3>
              <p>
                Choose where you want to fly and plan your flights ahead of time! <br>
                This simply allows one to create a list of flights to complete in the future. This stores the airline,
                flight number, aircraft (from your fleet) along with the origin and destination airport. Once ready to
                fly simply click 'dispatch' and automatically fill in the planning page and generate a SimBrief flight
                plan.
              </p>
            </div>
          </v-col>
          <v-col md="6" cols="12">
            <div>
              <img style="width: 100%; margin: 5px" src="@/assets/screenshots/current_schedule_crop.png"/>
            </div>
          </v-col>
        </v-row>
        <hr>
        <v-row>
          <v-col md="6" cols="12">
            <div>
              <img style="width: 100%; margin: 5px" src="@/assets/screenshots/airline_schedules.png"/>
            </div>
          </v-col>
          <v-col md="6" cols="12" class="infoText">
            <div>
              <h3>Real Flight Schedules</h3>
              <p>
                Lookup and choose to fly real airline routes using The Flight Sim Kit! <br>
                We store a vast number of real world airline flights allowing our users to easily fly real world routes
                true to the airline one is flying with. We offer two methods of doing this: by searching with optional
                parameters of airline, origin and destination OR just by airline and viewing a map of that airlines
                routes.
              </p>
            </div>
          </v-col>
        </v-row>
        <hr>
        <v-row>
          <v-col md="6" cols="12" class="infoText">
            <div>
              <h3>Fleet and Aircraft Log</h3>
              <p>
                Track all of your flights of each of your aircraft through The Flight Sim Kit! <br>
                All of the flights created through The Flight Sim Kit (and any landings performed during a flight) are
                tied to a specific aircraft in ones fleet. This allows for the tracking of hours, flight time, aircraft
                location and many more for specific aircraft. Additionally we offer the ability to draw a map of the
                routes completed by a given aircraft.
              </p>
            </div>
          </v-col>
          <v-col md="6" cols="12">
            <div>
              <img style="width: 100%; margin: 5px" src="@/assets/screenshots/fleet_and_log.png"/>
            </div>
          </v-col>
        </v-row>
        <hr>
        <v-row>
          <v-col md="6" cols="12">
            <div>
              <img style="width: 100%; margin: 5px" src="@/assets/screenshots/share_crop.png"/>
            </div>
          </v-col>
          <v-col md="6" cols="12" class="infoText">
            <div>
              <h3>Share Page</h3>
              <p>
                Share your flight progress with all of your friends through The Flight Sim Kit! <br>
                All of our flights can be shared with anyone else in the world! We offer a link which anyone can visit
                which shows live progress of your flight, ETA, altitude and speed progress and, if landed, any landing
                data. This page can be visited while the flight is active as well any time in the future if the flight
                has been completed.
              </p>
            </div>
          </v-col>
        </v-row>
        <hr>
      </div>
    </v-container>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import L, {latLng, icon} from 'leaflet'
  import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LControlAttribution, LIcon } from 'vue2-leaflet'
  import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker'


  import airplaneIcon from '@/assets/icons/airplane.png'

  let baseURL = ''
  let webSocketBaseURL = ''
  if (process.env.NODE_ENV === 'production'){
    baseURL = new URL(window.location).origin
    webSocketBaseURL = "wss://" + new URL(window.location).host
  }else{
    baseURL = 'http://127.0.0.1:8080'
    webSocketBaseURL = 'ws://127.0.0.1:8080'
  }

  export default {
    name: 'Home',
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LPopup,
      LTooltip,
      LControlAttribution,
      LIcon,
      'v-rotated-marker': Vue2LeafletRotatedMarker
    },
    data: function () {
      return {
        connection: null,
        connectionRetryTimer: null,
        connectionClosed: false,
        mapCenter: L.latLng(0,0),
        currentData: {},
        dataInErrorState: false,
        zoom:2,
        url:'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
        prefix: "",
        attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        icon: L.icon({
          iconUrl: airplaneIcon,
          iconSize: [30, 30],
          iconAnchor: [15, 15]
        })
      }
    },
    created: function () {
      this.initiateWebsocket(this.currentUser['user'])
    },
    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        isLoggedIn: 'isLoggedIn'
      }),
      pilotCount: function(){
        return Object.keys(this.currentData).length
      }
    },
    methods: {
      initiateWebsocket: function(username){
        this.connection = new WebSocket(webSocketBaseURL + "/allDataClients")
        this.connection.onmessage = function (event){
          let receivedData = JSON.parse(event.data)
          if (receivedData === null){
            this.currentData = []
            return
          }

          if (!('error' in receivedData)){
            this.currentData = receivedData
            this.dataInErrorState = false
          }else{
            this.dataInErrorState = true
          }
        }.bind(this)

        this.connection.onopen = function (event){
          console.log("Connection opened")
        }.bind(this)

        this.connection.onclose = function (event) {
          console.log("Connection closed")

          if (!this.connectionClosed) {
            console.log("Retrying connection in 5 seconds")
            this.connectionRetryTimer = setTimeout(function (){
              this.initiateWebsocket()
            }.bind(this), 5000)
          }else{
            console.log("CLOSE Clean")
            this.connectionClosed = false
          }
        }.bind(this)

        this.connection.onerror = function(event){
            console.log("Connection error")
        }.bind(this)
      },
      radians_to_degrees: function(radians)
      {
        let pi = Math.PI;
        return radians * (180/pi);
      },
      getLatLng: function(lat, lng){
        return L.latLng(lat, lng)
      }
    },
    beforeDestroy() {
      if (this.connection !== null) {
        this.connectionClosed = true
        this.connection.close(1000);
        this.connection = null;
      }
      console.log("before destroy");
    }
  }
</script>

<style>
  .infoText {
    display: flex;
    align-items: center;
  }
</style>
