import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    props: true
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/flights',
    name: 'Flights',
    component: () => import('../views/Flights'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/fleet',
    name: 'Fleet',
    component: () => import('../views/Fleet'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/livemap',
    name: 'Live Map',
    component: () => import('../views/LiveMap'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/navigraphTest',
    name: 'Navigraph',
    component: () => import('../views/NavigraphTest'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:username/streamData',
    name: 'Stream Data',
    component: () => import('../views/StreamData'),
    meta: {
      header: false
    }
  },
  {
    path: '/:username/streamMap',
    name: 'Stream Map',
    component: () => import('../views/StreamMap'),
    meta: {
      header: false
    }
  },
  {
    path: '/shares/:flightID',
    name: 'FlightShare',
    component: () => import('../views/FlightShare'),
    props: true
  },
  {
    path: '/social',
    name: 'Social',
    component: () => import('../views/Social'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('../views/Help'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/vfr',
    name: 'VFR Planner',
    component: () => import('../views/VFRPlanner'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next({name: 'Login'})
    } else {
      const currentUser = store.getters.getCurrentUser
      const loginRes = await checkLogin(currentUser)
      if (loginRes){
        next()
      }else {
        next({name: 'Login'})
        await store.dispatch('logoutUser')
      }
    }
  } else {
    next()
  }
})

async function checkLogin(currentUser) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Token " + currentUser['tokenHash']
    }
  }
  let result;
  await fetch(Vue.prototype.$baseURL + "/" + currentUser['user'] + "/loginCheck", requestOptions)
    .then(response => {
      result = response.ok
      return response.ok;
    })

  return result
}

export default router
